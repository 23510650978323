import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="brand-filter"
export default class extends Controller {
  static targets = ["popup", "content", "item", "btn", "input"]

  brands = []

  connect() {
    console.log("brand filter controller connected");
    console.log(this.itemTargets);
    let counter = 0;
    this.itemTargets.forEach((item) => {
      if (item.classList.length == 2) {
        counter += 1
      }
    })
    this.btnTarget.value = "Apply filter ( " + counter + " selected )"
  }
  showPopup(e) {
    e.preventDefault();
    this.contentTarget.classList.add("d-none")
    this.popupTarget.classList.remove("d-none")
  }

  closePopup(e) {
    e.preventDefault();
    this.popupTarget.classList.add("d-none")
    this.contentTarget.classList.remove("d-none")
  }

  selectBrand(e) {
    e.preventDefault();
    let counter = 0;
    let brand_ids = [];
    // console.log(counter);
    // console.log(this.itemTargets[e.params["index"]].innerHTML.trim());
    this.itemTargets[e.params["index"]].classList.toggle("selected")
    this.itemTargets.forEach((item) => {
      // console.log(item.getAttribute("data-brand-filter-id-param")) //value
      if (item.classList.length == 2) {
        counter += 1
        brand_ids.push(item.getAttribute("data-brand-filter-id-param"))
      }
    })
    this.btnTarget.value = "Apply filter ( " + counter + " selected )"
    this.inputTarget.value = brand_ids
    console.log(brand_ids);
  }

}
