import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="impressions"
export default class extends Controller {
  static targets = ["button"];

  connect() {
    console.log("Hello, Impression controller!", this.element);
    document.addEventListener("DOMContentLoaded", () => {
      this.buttonTargets.forEach((link) => {
        onVisible(link, () => {
          dataLayer.push({
            event: "impression",
            link_text: link.innerText,
          });
          console.log(dataLayer);
        });
      });
    });
  }
}

function onVisible(element, callback) {
  new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        callback(element);
        observer.disconnect();
      }
    });
  }).observe(element);
  if (!callback) return new Promise((r) => (callback = r));
}
