import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="home-form-validator"
export default class extends Controller {
  connect() {
    console.log("Helloooooowww");

    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // console.log(userAgent);
      // console.log(window.location.protocol);
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    }

    if (getMobileOperatingSystem() == "Android") {
      const url =
        "intent://canrocket.herokuapp.com/#Intent;scheme=kans;package=com.dataheka.kans;end";
      window.location.replace(url);
    } else if (getMobileOperatingSystem() == "iOS") {
      window.location = "kans://sketches";
      setTimeout(() => {
        window.location.replace("https://testflight.apple.com/join/b1BjbyWT");
      }, 10000);
    } else {
      console.log("device " + getMobileOperatingSystem());
    }

    // window.location.replace("https://instagram.com");
    // console.log('device ' + getMobileOperatingSystem());

    const submitBtn = document.getElementById("submit-btn");
    const fileUpload = document.getElementById("file-upload");
    const uploadDiv = document.getElementsByClassName("upload-div")[0];
    const uploadLabel = document.getElementById("custom-file-upload");
    const browseFileBtn = document.getElementsByClassName("browse-file-btn")[0];
    const img = document.getElementById("preview-img");
    const cta = document.getElementsByClassName("cta")[0];
    console.log(fileUpload);
    console.log(submitBtn);
    console.log(uploadLabel);
    console.log(uploadDiv);
    console.log(img);

    fileUpload.onchange = (evt) => {
      const [file] = fileUpload.files;
      if (file) {
        cta.style.display = "none";
        console.log(img.classList);
        img.classList.remove("d-none");
        submitBtn.classList.remove("d-none");
        uploadDiv.style.height = "44vh";
        browseFileBtn.textContent = "Change";
        img.src = URL.createObjectURL(file);
      }
    };

    // fileUpload.addEventListener("change", (event) => {
    //   uploadLabel.style.display = "none";
    //   const preview = document.querySelector("img");
    //   let file = document.querySelector("input[type=file]").files[0];
    //   console.log(file);
    //   let reader = new FileReader();

    //   reader.onloadend = function () {
    //     console.log(reader.result);
    //     preview.src = reader.result;
    //   };

    //   if (file) {
    //     reader.readAsDataURL(file);
    //     console.log(reader.readAsDataURL(file));
    //   } else {
    //     preview.src = "";
    //   }
    // });
  }
}
