import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sketch-loader"
export default class extends Controller {
  // static targets = ["banner", "animation"]
  connect() {
    console.log("Hellowwwwwww connecte");
  }
  runSearch(e) {
    e.preventDefault();
    this.bannerTarget.classList.add("d-none")
    this.animationTarget.classList.remove("d-none")
    setTimeout(() => {
      e.target.submit()
    }, 100);
  }
}
