import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="get-palette"
export default class extends Controller {
  connect() {
    // Console log to check Controller connexion
    console.log("Hellow");

    // Variables >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // Canvas variables
    // Main canva
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = document.getElementById("my-image").getAttribute("src");
    const canvas = document.getElementById("image-picker_image_canvas");
    const ctx = canvas.getContext("2d");

    // Zoom canva
    const imgZoom = new Image();
    imgZoom.crossOrigin = "anonymous";
    imgZoom.src = document.getElementById("my-image").getAttribute("src");
    const zoomCanva = document.getElementById("cursorCanva");
    const zoomLevel = 30;

    // Palette variables
    const fields = document.getElementById("palette-fields");
    const submitButton = document.getElementById("submit-color");
    const colorsInput = document.getElementById("colors_inputs");
    const addColorBtn = document.getElementsByClassName("icon-add-12px");
    const deleteColorBtn = document.getElementsByClassName("icon-delete-12px");
    const defaultBtn = document.querySelectorAll(".default-btn");
    const firstPickBtn = document.querySelectorAll("#pick");
    const allAddColorBtn = Array.prototype.slice
      .apply(addColorBtn)
      .concat(Array.prototype.slice.apply(firstPickBtn));
    const colorChecker = document.getElementById("color-checker");
    const colorCheckerHexCode = document.getElementById(
      "color-checker-hex-code"
    );

    // Viewport variables
    let touchMode = false;

    // Picker behaviour variables
    const newCursor = document.getElementById("cursor-picker");
    var x = 0,
      y = 0,
      mousedown = false;

    // Pinch Zoom variables
    var image_x = 0,
      image_y = 0;
    var zoom = 0.5;
    var mouse_x = 0,
      mouse_y = 0,
      finger_dist = 0;

    // We define the size of the main canva
    canvas.width = window.screen.width;
    canvas.height = window.screen.height * 0.6;

    // We define the size of the zoom canva
    const zoomCanva_w = zoomCanva.width;
    const zoomCanva_h = zoomCanva.height;
    console.log(zoomCanva_w);

    const canvas_w = canvas.width,
      canvas_h = canvas.height;

    // Functions >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // Pick function to get color of the hovered pixel
    function pick(event, destination) {
      const bounding = canvas.getBoundingClientRect();
      let x = 0;
      let y = 0;
      if (touchMode) {
        x = event.touches[0].pageX - bounding.left;
        y = event.touches[0].pageY - bounding.top;
      } else {
        x = event.clientX - bounding.left;
        y = event.clientY - bounding.top;
      }
      const pixel = ctx.getImageData(x, y, 1, 1);
      const data = pixel.data;

      const rgba = `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${data[3] / 255})`;

      destination.style.background = rgba;
      destination.style.borderColor = rgba;
      // destination.textContent = `(${x}, ${y})`;

      return rgba;
    }

    // Get the Hex code of a color
    function hexColour(c) {
      let hex = Math.abs(c).toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    // Convert RGB code to Hex code
    function rgbToHex(r, g, b) {
      return "#" + hexColour(r) + hexColour(g) + hexColour(b);
    }

    function update_canvas() {
      const mainCanvasCTX = canvas.getContext("2d");
      // console.log(zoom);

      const limitXRight = img.width - (canvas_w * zoom) / 2 - zoomLevel / 2;
      const limitXLeft = - zoomLevel / 2 - (canvas_w * zoom) / 2;
      const limitYTop = - zoomLevel / 2 - (canvas_h * zoom) / 2 ;
      const limitYBottom = img.height - zoomLevel / 2 - (canvas_h * zoom) / 2;

      // Keep picture in bounds

      // Limit X Right
      if (image_x > limitXRight) image_x = limitXRight;

      // Limit Y Bottom
      if (image_y > limitYBottom) image_y = limitYBottom;

      // Limit X Left
      if (image_x < limitXLeft) image_x = limitXLeft;

      // Limit Y Top
      if (image_y < limitYTop) image_y = limitYTop;

      // console.log("x="+image_x);
      // console.log("y="+image_y);

      // Draw the scaled image onto the canvas
      mainCanvasCTX.clearRect(0, 0, canvas_w, canvas_h);
      mainCanvasCTX.drawImage(
        img,
        image_x,
        image_y,
        canvas_w * zoom,
        canvas_h * zoom,
        0,
        0,
        canvas_w,
        canvas_h
      );

      const pixelatedZoomCtx = document
        .getElementById("cursorCanva")
        .getContext("2d");
      pixelatedZoomCtx.imageSmoothingEnabled = false;
      pixelatedZoomCtx.mozImageSmoothingEnabled = false;
      pixelatedZoomCtx.webkitImageSmoothingEnabled = false;
      pixelatedZoomCtx.msImageSmoothingEnabled = false;

      const zoomCursor = (ctx, x, y) => {
        // console.log(x);
        // console.log(
        //   Math.min(Math.max(0, x - zoomLevel / 2), img.width - zoomLevel)
        // );
        ctx.clearRect(0, 0, zoomCanva_w, zoomCanva_h);
        ctx.drawImage(
          canvas,
          Math.min(Math.max(0, x), img.width),
          Math.min(Math.max(0, y), img.height),
          zoomLevel,
          zoomLevel,
          0,
          0,
          pixelatedZoomCtx.canvas.width,
          pixelatedZoomCtx.canvas.height
        );

        // Adding a circle and a cross to select color more precisely
        const rayon = 30;
        const centerX = pixelatedZoomCtx.canvas.width / 2;
        const centerY = pixelatedZoomCtx.canvas.height / 2;
        const crossLength = 5;
        ctx.beginPath();
        ctx.lineWidth = 5;
        ctx.strokeStyle = "white";
        ctx.arc(centerX, centerY, rayon, 0, 2 * Math.PI, false);
        ctx.moveTo(centerX, centerY - crossLength);
        ctx.lineWidth = 3;
        ctx.lineTo(centerX, centerY + crossLength);
        ctx.moveTo(centerX - crossLength, centerY);
        ctx.lineTo(centerX + crossLength, centerY);
        ctx.stroke();
        ctx.strokeStyle = "white";

        const pixel = ctx.getImageData(60, 60, 1, 1); // Allow to get color when we move and zoom canvas
        const data = pixel.data;

        const rgba = `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${
          data[3] / 255
        })`;

        const newCursor = document.getElementById("cursor-picker");
        newCursor.children[0].style.background = rgba;
        newCursor.children[0].style.borderColor = rgba;
        colorChecker.style.background = rgba;
        colorCheckerHexCode.textContent = rgbToHex(
          data[0],
          data[1],
          data[2]
        ).toUpperCase();
      };

      zoomCursor(pixelatedZoomCtx, canvas_w / 2, canvas_h / 2); // Initialize cursor zoom content
    }

    function reset_settings() {
      if (img.height > img.width) {
        zoom = img.height / canvas_h;
      } else {
        zoom = img.width / canvas_w;
      }
      image_x = 0;
      image_y = -(img.height - zoomLevel / 2 - (canvas_h * zoom) / 2);
      newCursor.style.top = canvas_h / 2 + "px";
      newCursor.style.left = canvas_w / 2 + "px";
      update_canvas(); // Draw the image in its new position
    }

    function get_distance(e) {
      var diffX = e.touches[0].clientX - e.touches[1].clientX;
      var diffY = e.touches[0].clientY - e.touches[1].clientY;
      return Math.sqrt(diffX * diffX + diffY * diffY); // Pythagorean theorem
    }

    // Listeners >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // img Listener to drawn the img into the canva

    img.addEventListener(
      "load",
      function () {
        reset_settings();
      },
      false
    ); // Reset (x,y,zoom) when new image loads

    // Listener Touch move on Cursor
    // newCursor.addEventListener(
    //   "touchmove",
    //   function (e) {
    //     if (
    //       e.target &&
    //       e.target.parentNode.classList.contains("image-picker_cursor")
    //     ) {
    //       e.preventDefault();
    //       e.target.parentNode.style.left =
    //         e.touches[0].pageX - canvas.getBoundingClientRect().left + "px";
    //       e.target.parentNode.style.top =
    //         e.touches[0].pageY - canvas.getBoundingClientRect().top + "px";
    //       pick(e, e.target.parentNode.children[0]);
    //     }
    //   },
    //   true
    // );

    // We add a listener on Pipette buttons to add the cursor color into the palette
    Array.from(allAddColorBtn).forEach(function (elem) {
      elem.addEventListener("click", (event) => {
        const color =
          document.getElementById("cursor-picker").children[0].style.background;
        const paletteSelectedColors =
          elem.parentElement.parentElement.parentElement.children[0];
        if (elem.id == "pick") {
          paletteSelectedColors.children[0].classList.add("d-none");
          paletteSelectedColors.parentElement.children[1].classList.remove(
            "d-none"
          );
        }
        let div = document.createElement("div");
        div.className = "is-visible";
        div.id =
          paletteSelectedColors.id +
          "_" +
          (paletteSelectedColors.childElementCount + 1);
        div.style.backgroundColor = color;
        div.style.color = color;
        // console.log(div);
        paletteSelectedColors.appendChild(div);
      });
    });

    // We add a listener on canvas to zoom with mouse wheel
    canvas.addEventListener(
      "wheel",
      function (e) {
        if (e.deltaY < 0) {
          zoom = zoom * 1.5;
        } else {
          zoom = zoom / 1.5;
        }
        let zoomMin = 0;
        let zoomMax = 0;
        if (img.height > img.width) {
          zoomMin = img.height / canvas_h;
        } else {
          zoomMin = img.width / canvas_w;
        }
        zoomMax = zoomMin / 15;
        if (zoom > zoomMin) zoom = zoomMin;
        if (zoom < zoomMax) zoom = zoomMax;
        update_canvas();
      },
      false
    );

    // We add a listener on canvas to move with mouse
    canvas.addEventListener(
      "mousemove",
      function (e) {
        if (e.buttons > 0) {
          window.getSelection().empty();
          image_x = image_x + zoom * (mouse_x - e.clientX);
          image_y = image_y + zoom * (mouse_y - e.clientY);
        }
        mouse_x = e.clientX;
        mouse_y = e.clientY; // Save for next time
        update_canvas(); // draw the image in its new position
      },
      { passive: false }
    );

    // We add a touchstart listener on canvas to get fingers positions and calculate finger distance
    canvas.addEventListener(
      "touchstart",
      function (e) {
        if (e.touches.length > 1) {
          // if multiple touches (pinch zooming)
          finger_dist = get_distance(e); // Save current finger distance
        } // Else just moving around
        mouse_x = e.touches[0].clientX; // Save finger position
        mouse_y = e.touches[0].clientY; //
      },
      false
    );

    // We add a touchmove listener on canvas to zoom canvas according to old and new distance of the fingers
    canvas.addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault(); // Stop the window from moving
        if (e.touches.length > 1) {
          // If pinch-zooming
          var new_finger_dist = get_distance(e); // Get current distance between fingers
          zoom = zoom * Math.abs(finger_dist / new_finger_dist); // Zoom is proportional to change
          let zoomMin = 0;
          let zoomMax = 0;
          if (img.height > img.width) {
            zoomMin = img.height / canvas_h;
          } else {
            zoomMin = img.width / canvas_w;
          }
          zoomMax = zoomMin / 15; // We define maximum zoom relatively to minZoom
          if (zoom > zoomMin) zoom = zoomMin;
          if (zoom < zoomMax) zoom = zoomMax;
          finger_dist = new_finger_dist; // Save current distance for next time
        } else {
          // Else just moving around
          const decalX = zoom * (mouse_x - e.touches[0].clientX)
          const decalY = zoom * (mouse_y - e.touches[0].clientY)
          image_x = image_x + decalX; // Move the image
          image_y = image_y + decalY; //
          console.log("image_x : " + image_x);
          console.log("mouse_x : " + mouse_x);
          console.log("touchX : " + e.touches[0].clientX);
          mouse_x = e.touches[0].clientX; // Save finger position for next time
          mouse_y = e.touches[0].clientY; //
        }
        update_canvas(); // draw the new position
      },
      false
    );

    // We add a touchend listener on canvas to get coordinates of fingers when touch end
    canvas.addEventListener(
      "touchend",
      function (e) {
        mouse_x = e.touches[0].clientX;
        mouse_y = e.touches[0].clientY; // could be down to 1 finger, back to moving image
      },
      false
    );

    // Detect if Touch or not
    window.addEventListener("touchstart", function () {
      touchMode = true;
    });

    // Click listener on default palette btn
    Array.from(defaultBtn).forEach(function (elem) {
      elem.addEventListener("click", (event) => {
        // const selectedField = document.getElementsByClassName("selected")[0].id;
        Array.from(elem.parentElement.children).forEach(function (elem) {
          elem.classList.remove("active");
        });
        elem.classList.add("active");
      });
    });

    // Click listener on Deleting a color to the palette of the selected style
    Array.from(deleteColorBtn).forEach(function (elem) {
      elem.addEventListener("click", (event) => {
        // const selectedField = document.getElementsByClassName("selected")[0].id;
        const paletteSelectedColors =
          elem.parentElement.parentElement.parentElement.children[0];
        if (paletteSelectedColors.childElementCount == 2) {
          paletteSelectedColors.children[0].classList.remove("d-none");
          paletteSelectedColors.parentElement.children[1].classList.add(
            "d-none"
          );
          paletteSelectedColors.children[0].children[0].classList.add("active");
          paletteSelectedColors.children[0].children[1].classList.remove(
            "active"
          );
        }
        paletteSelectedColors.removeChild(paletteSelectedColors.lastChild);
      });
    });

    // Click listener on Submit button to push all the colors from all palette to the Form
    submitButton.addEventListener("click", (event) => {
      event.preventDefault();
      let style_list = [];
      let paletteColors = document.getElementsByClassName(
        "palette-selector_colors"
      );
      // Je parcours chaque style
      Array.from(paletteColors).forEach((element) => {
        let n = 1
        let colors = [];
        //Je parcours chaque couleur par style
        Array.from(element.children).forEach((elem) => {
          if (!elem.classList.contains("default-palette-cta")) {
            const regex = /\((.*)\)/;
            let rgb_colors = elem.style.backgroundColor
              .match(regex)[1]
              .split(",");
            let r = rgb_colors[0];
            let g = rgb_colors[1];
            let b = rgb_colors[2];
            if (!colors.includes(rgbToHex(r, g, b))) {
              colors.push(rgbToHex(r, g, b));
            }
          }
        });
        if (colors.length > 0) {
          // console.log(element.id + ':' + colors.join(','));
          style_list.push(element.id + ':' + colors.join(','))
        } else {
          // console.log(element.id);
          style_list.push(element.id)
        }
        // Pour chaque style, je veux un tableau contenant le nom du style et les couleurs. Exemple : fillin:#000000,#ffffff,#ff0000
        // When I have my list of color for a style, I add it to the colors array with style name
      });
      colorsInput.value = style_list.join(";");
      console.log(style_list.join(";"));
      // console.log(colorsInput.value);
      fields.submit();
    });

    // div event mousedown
    newCursor.addEventListener(
      "mousedown",
      function (e) {
        // mouse state set to true
        mousedown = true;
        // subtract offset
        x = elem.offsetLeft - e.clientX;
        y = elem.offsetTop - e.clientY;
      },
      true
    );

    // div event mouseup
    newCursor.addEventListener(
      "mouseup",
      function (e) {
        // mouse state set to false
        mousedown = false;
      },
      true
    );

    // element mousemove to stop
    newCursor.addEventListener(
      "mousemove",
      function (e) {
        // Is mouse pressed
        if (mousedown) {
          // Now we calculate the difference upwards
          newCursor.style.left = e.clientX + x + "px";
          newCursor.style.top = e.clientY + y + "px";
          pick(e, newCursor.children[0]);
        }
      },
      true
    );
  }
}
