import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cans-selector"
export default class extends Controller {
  static targets = ["can", "cart", "cartItem", "fullCart"]
  connect() {
    console.log("cans selector controller connected");
  }

  selectCan(e) {
    e.preventDefault();
    console.log(this.canTargets);
    console.log(e.params["id"]);
    console.log(this.canTargets[e.params["index"]]);
    this.fullCartTarget.classList.remove("d-none")
    this.canTargets[e.params["index"]].classList.toggle("selected")
    let html = `<div class="cart-item "data-cans-selector-target="cart-item" data-action="click->cans-selector#removeCartItem">`+ this.canTargets[e.params["index"]].lastElementChild.outerHTML + `</div>`
    console.log(this.canTargets[e.params["index"]].lastElementChild);
    // this.canTargets[e.params["index"]].insertAdjacentHTML('beforeend', "Added to Cart")
    this.cartTarget.insertAdjacentHTML('beforeend', html)
  }

  removeCartItem(e) {
    e.preventDefault();
    console.log(this.canTargets);
    this.canTargets[e.params["index"]].classList.toggle("selected")
    let html = `<div class="cart-item "data-cans-selector-target="cartItem" data-action="click->cans-selector#removeCartItem">`+ this.canTargets[e.params["index"]].lastElementChild.outerHTML + `</div>`
    console.log(this.canTargets[e.params["cart-index"]].lastElementChild);
    this.cartTarget.insertAdjacentHTML('beforeend', html)
  }
}
