import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.element.addEventListener("click", (event) => {
      switch (event.target.classList[0]) {
        case "store_button":
          dataLayer.push({
            event: "click_store_button",
            store_button: event.srcElement.alt,
          });
          break;

        case "fa-brands":
          dataLayer.push({
            event: "click_social_button",
            store_button: event.target.classList[1],
          });
          break;

        default:
          break;
      }
    });
  }
}
